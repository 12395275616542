.lost-bike {
  position: absolute;
  filter: drop-shadow(0 0 20px rgba(255,255,255,0.5));
  @keyframes bike-spinout {
    0% {
      transform: scale(0.1, 0.1) rotate(0deg);
      top: -200px;
      left: -200px;
    }
    100% {
      transform: scale(1, 1) rotate(360deg);
      top: 100%;
      left: 100%;
    }
  }
  animation: 5s forwards bike-spinout;
}
