.Quest {
  height: 100%;
  width: 100%;
  animation-name: quest-transition;
  animation-fill-mode: both;
  animation-duration: 3s;
  opacity: 0;
  position: relative;
}

@keyframes quest-transition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}