  .background-target {
    height: 100%;
    width: 100%;
    top: -50%;
    position: absolute;
    opacity: 1;
    @keyframes spin {
      0% { transform: rotate(0deg); }
      50% { transform: rotate(180deg); }
      100% { transform: rotate(360deg); }
    }
    animation: 150s forwards linear infinite spin;
  }
