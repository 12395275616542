@import '../constants.scss';

.NarratorChoice {
  height: 100%;
  min-height: 300px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .prompt {
    padding: 10px 20px;
    @media screen and (max-width: 600px) {
      padding: 10px;
    }
  }
  .basic-button {
    animation-name: slide-in;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    &.option {
      width: 90%;
      margin-top: 10px;
      padding: 8px 14px;
      font-size: 1.2rem;
      text-align: center;
      border-width: 6px;
      background: rgba(255,255,255,0.5);
      @media screen and (max-width: 600px) {
        margin-top: 8px;
        padding: 8px 12px;
        font-size: 3vw;
      }
      &:hover {
        background-color: $color-aqua-1;
      }
      &::after {
        // Force parent div to have some padding under button
        // when scrolling overflow
        content: '';
        position: absolute;
        top: 100%;
        height: 20px;
        width: 1px;
      }
    }
  }
}
.chosen {
  padding: 20px;
  background: rgba(82, 58, 40, 0.5);
  color: white;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
  font-family: 'DotGothic16';
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.result {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 73px;
  .result-text {
    flex-grow: 3;
    margin-top: 20px;
    text-align: center;
    opacity: 0;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: fade-in;
  }
}

.next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}