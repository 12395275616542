.KnowledgeQuest {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  // font-family: Arial;
  font-family: 'DotGothic16';
  font-size: 22px;
  color: #222;
  .container {
    background-color: white;
    box-shadow: 0 0 70px 20px rgba(255,255,255,0.5);
    padding: 44px 80px;
    display: flex;
    width: 70%;
    @media screen and (max-width: 768px) {
      padding: 24px;
      width: 90%;
    }
    flex-direction: column;
    .header {
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        display: inline;
        position: relative;
        top: -1px;
      }
      .title {
        margin-left: 20px;
        font-weight: bold;
        display: inline;
      }
    }
    hr {
      border: 1px solid gray;
      width: 100%;
    }
    .prompt {
      padding: 8px;
      border: 2px solid gray;
      background-color: #b2ece5aa;
      margin-bottom: 25px;
      text-align: center;
    }
    .question {
      margin-bottom: 40px;
      font-size: 22px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .options {
      &:not(.success) {
        .option:not(.selected):hover {
          background-color: rgba(0,0,0,0.04);
          cursor: pointer;
          * {
            cursor: pointer;
          }
        }
      }
      .option {
        font-size: 22px;
        display: flex;
        border-radius: 8px;
        padding-bottom: 20px;
        padding-top: 10px;
        margin-left: 30px;
        padding-left: 10px;
        position: relative;
        transition: all 0.5s;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          margin-left:0;
        }
        &.selected {
          background-color: rgba(44, 190, 247, 0.7);
          box-shadow: 0 0 1px 1px gray;
          &::after {
            content: 'Incorrect answer';
            animation: fade-in 1s;
            text-align: right;
            width: 100%;
            position: absolute;
            left: -20px;
            bottom: 10px;
            color: rgb(151, 60, 23);
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
          .index {
            background-color: black;
            color: white;
          }
          &.success {
            &::after {
              content: 'Correct answer';
              color: blue;
            }
          }
        }
        .index {
          text-transform: uppercase;
          font-weight: bold;
          width: 50px;
          border-radius: 28px;
          border: 1px solid gray;
          margin-right: 30px;
          text-align: center;
          background-color: rgb(212, 240, 255);
        }
      }
    }
  }
}