.PizzaQuest {
  $fontfamily: 'DotGothic16';
  height: 100%;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontfamily;
  .basic-button {
    margin-top: 0 !important;
  }
  .container {
    @media screen and (max-width: 768px) {
      align-self: flex-start;
      margin-top: 60px;
    }
    background: rgba(0,0,0,0.7);
    border-radius: 20px;
    padding: 20px;
  }
  .prompts {
    :nth-child(2) {
      margin-bottom: 20px;
    }
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .equation {
    text-align: center;
    font-size: 40px;
    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input[type=text], input[type=number] {
      font-size: 40px;
      padding: 10px;
      width: 100px;
      margin-right: 40px;
      font-family: $fontfamily;
      margin-left: 10px;
      background: rgba(255,255,255,0.8);
      outline: none;
    }
    input[type=submit] {
      font-size: 18px;
      padding: 14px;
      font-family: $fontfamily;
      height: 70px;
    }
  }
  .success {
    margin-top: 20px;
    text-align: center;
  }
}