.FightEvent {
  position: fixed;
  width: 100%;
  height: 100%;
  .ball {
    position: absolute;
    top: -200px;
    transition: top 2s;
    @keyframes fall {
      from {
        top: 100%;
        transform: scale(3,3) rotate(0deg);
        opacity: 0;
      }
      to {
        top: 0%;
        transform: scale(0,0) rotate(360deg);
        opacity: 1;
      }
    }
  }
}
