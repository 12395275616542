$color-1: rgb(47, 118, 118);
$color-attention: rgb(159, 223, 55);

$border-radius-1: 8px;

$main-background: rgba(255,255,255,0.035);
$section-background: rgba(255,255,255,0.1);
$section-hightlight: rgba(50, 64, 66, 0.815);
$section-hightlight-outline: rgba(78, 114, 119, 0.815);

$section-light: rgba(255,255,255,0.1);
