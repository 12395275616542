@import url('https://fonts.googleapis.com/css2?family=Reggae+One&family=Ribeye+Marrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vesper+Libre&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=News+Cycle:400,700');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@import './constants.scss';

.App-cover {
  .arcade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.insert {
      .token {
        @keyframes insert-token {
          from { top: 0}
          to { top: 100px; }
        }
        animation: 1s forwards insert-token;
      }
    }
    .token {
      top: 0;
      position: relative;
      transition: all 1s;
      @keyframes rotate-token {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
      animation: 4s infinite forwards rotate-token;
    }
  }
}

.App {
  background-color: black;
  height: 100vh;
  width: 100vw;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  cursor: default;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  height:100vh;
  width:100vw;
  background-color:rgb(19, 20, 22);
  overflow:hidden;
}
.App.play {
  font-family: 'DotGothic16';
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    position: relative;
    top: -20px;
  }
  to {
    position: relative;
    top: 0;
  }
}


.basic-button {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  outline: none;
  &.spaced {
    margin-top: 10px;
    padding: 12px;
  }
  &.hidden {
    opacity: 0 !important;
  }
}
*:not(.failure) {
    .basic-button {
      margin-top: 30px;
      align-self: center;
      padding: 4px 14px;
      border: 8px solid #aaa;
      background-color: ghostwhite;
      transition: all 0.2s ease-in-out;
      border-bottom-color: #444;
      border-right-color: #444;
      &:hover {
        background-color: white;
        border-left-color: $color-aqua-3;
        border-top-color: $color-aqua-3;
        border-bottom-color: $color-aqua-4;
        border-right-color: $color-aqua-4;
      }
    }
}