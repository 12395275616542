.house {
  @keyframes blur-to-focus {
    from {
      filter: blur(50px);
    }
    to {
      filter: blur(0px);
    }
  }
  cursor: none;
  position: fixed;
  top: 0;
  align-self:flex-start;
  animation: 4s ease-in-out forwards blur-to-focus;
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    height: 280px;
  }
}
