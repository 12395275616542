.Wheel {
  $fixed-top: -120px;
  $fixed-wheel-size: 100px;
  $wheel-size: 60px;
  $center-hero-size: 100px;
  position: relative;
  position: fixed;
  width: 100%;
  opacity: 1;
  height: 100%;
  &.hidden {
    display: none;
    opacity: 0;
  }
  .wheel-container {
    width: $wheel-size;
    position: absolute;
    top: 50%;
    &.falling {
      animation: 12s forwards ease-out falling;
      animation-timing-function: linear;
      animation-direction: normal;
      .hero {
        animation: 1s alternate infinite ease-in-out rotate-wheel;
      }
    }
    &.bounce {
      animation: 8s forwards infinite slide;
      animation-timing-function: linear;
    }
    &.intro.fixed {
      .wheel-image {
        animation: 3s forwards 3 linear rotate-wheel;
      }
    }
    &.intro:not(.fixed) {
      .wheel-image {
        animation: 1s forwards infinite linear rotate-wheel;
        filter: drop-shadow(0 0 18px rgba(255,255,255, 0.5));
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
      }
    }
    &.fixed {
      transition: all 3s;
      position: absolute;
      top: $fixed-top;
      left: calc(50% - #{$fixed-wheel-size} / 2);
      &:not(.intro) {
        .hero {
          transition: top 1s linear, left 1s linear;
          transform: scale(0.25, 0.25);
          left: 0px;
          top: 8px;
        }
        &.level-1 {
          .hero {
            top: 70px;
            left: -10px;
          }
        }
        &.level-2 {
          .hero {
            top: 100px;
            left: -2px;
          }
        }
        &.level-3 {
          .hero {
            top: 93px;
            left: 9px;
          }
        }
        &.level-4 {
          .hero {
            top: 93px;
            left: 14px;
          }
        }
        .wheel-image {
          animation: unset;
          height: 100px;
          width: 100px;
        }
        &.center {
          .wheel-image {
            animation: 2s infinite forwards spin;
          }
          .Illusion {
            transition: all 4s;
            @keyframes hide {
              from {
                transform: scale(1, 1);
              }
              to {
                opacity: 0;
                display: none;
                transform: scale(5, 5);
              }
            }
            animation: 4s forwards 1 hide;
          }
        }
        &.outro, &.center {
          height: $wheel-size;
          top: calc(50% - 100px);
          left: calc(50%);
          transition: all 3s;
          .wheel-image {
            position: absolute;
            top: calc(50% - #{$fixed-wheel-size} / 2);
            left: calc(50% - #{$fixed-wheel-size} / 2);
          }
          .hero {
            transition: all 1s;
            position: absolute;
            transform: scale(1,1);
            top: calc(50% - #{$fixed-wheel-size} * 2 - 4px);
            left: calc(50% - #{$center-hero-size} / 2);
            width: $center-hero-size;
          }
        }
        &.outro {
          .Illusion {
            display: none;
          }
          .wheel-image {
            @keyframes grow-wheel {
              0% {
                transform: rotate(0deg);
              }
              50% {
                transform: rotate(360deg);
              }
              80% {
                opacity: 1;
              }
              99% {
                $grow-size: 9000px;
                width: $grow-size;
                height: $grow-size;
                top: calc(50% - #{$grow-size} / 2);
                left: calc(50% - #{$grow-size} / 2);
                transform: rotate(0deg);
                opacity: 0;
              }
              100% {
                opacity: 0;
                display: none;
                transform: rotate(0deg);
              }
            }
            animation: 4s linear forwards 2s grow-wheel;
          }
          .hero {
            @keyframes hero-outro {
              0% {
                transform: scale(1, 1) rotate(0deg);
              }
              50% {
                transform: scale(10,10) rotate(180deg);
              }
              80% {
                transform: scale(0.5,0.5);
                top: calc(50% - #{$center-hero-size});
                opacity: 1;
              }
              99% {
                opacity: 0;
                top: 1000px;
              }
              100% {
                transform: rotate(360deg);
                opacity: 0;
                display: none;
              }
            }
            animation: 4s forwards 2s hero-outro;
          }
        }
      }
      .Illusion {
        filter: drop-shadow(0 0 48px rgba(255,255,255, 0.1));
        height: $wheel-size * 3.5;
        width: $wheel-size * 3.5;
        top: 228px;
        left: 126px;
        opacity: 0.02;
      }
      &.intro {
        @keyframes move-to-fixed {
          0% {
            opacity: 0;
            left: -2000px;
            transform: scale(0.1, 0.1);
          }
          50% {
            opacity: 1;
            transform: scale(3.0, 3.0);
            top: 200px;
          }
          100% {
            opacity: 1;
            transform: scale(1.0, 1.0);
            top: $fixed-top;
          }
        }
        animation: 8s forwards move-to-fixed;
        .wheel-image {
          height: $fixed-wheel-size;
          width: $fixed-wheel-size;
          animation: 4s forwards linear rotate-wheel;
          animation-iteration-count: 3;
        }
        .hero {
          @keyframes hero-jump {
            0% {
              left: 20px;
              top: -12px;
              transform: scale(1.4, 1.4);
            }
            80% {
              left: 20px;
              top: -12px;
              transform: scale(1.4, 1.4);
            }
            90% {
              top: -18px;
              left: -12px;
              transform: scale(1.2);
            }
            100% {
              top: 70px;
              transform: scale(0.15, 0.15);
              left: -10px;
            }
          }
          animation: 12s forwards hero-jump;
          left: 20px;
          top: -12px;
          transform: scale(1.4);
        }
      }
    }
    .hero {
      width: $wheel-size;
      position: relative;
      top: 5px;
    }
    .wheel-image {
      filter: drop-shadow(0 0 48px rgba(255,255,255, 1));
      width: $wheel-size;
    }
    .Illusion {
      height: $wheel-size * 4;
      width: $wheel-size * 2;
      position: relative;
      bottom: -220px;
      left: 150px;
      opacity: 0.15;
      filter: drop-shadow(0 0 48px rgba(255,255,255, 1));
    }
  }
  @keyframes slide {
    0%{
      left: 0;
      top: 0;
      transform: rotate(0deg) scale(0.6, 0.6);
    }
    75%{
      left: calc(50% - #{$wheel-size} / 2);
      top: 0;
      transform: rotate(180deg) scale(1.4, 1.4);
    }
    50%{
      left: calc(100% - #{$wheel-size});
      top: 00px;
      transform: rotate(360deg) scale(1.0, 1.0);
    }
    25%{
      left: calc(50% - #{$wheel-size} / 2);
      top: 200px;
      transform: rotate(90deg) scale(1.0, 1.0);
    }
    100% {
      left: 0;
      top: 0;
      transform: rotate(0deg) scale(0.6, 0.6);
    }
  }
  @keyframes falling {
    0% {
      left: -200px;
      top: -500px;
      transform: scale(0.1, 0.1);
    }
    50% {
      left: 0px;
      top: 0px;
      transform: scale(0.5, 0.5);
    }
    60% {
      left: 50%;
      top: 50px;
      transform: scale(1, 1) rotate(90deg);
    }
    70% {
      left: -10px;
      top: 150px;
      transform: scale(1.7, 1.7) rotate(180deg);
    }
    80% {
      left: 70%;
      top: 50px;
      transform: scale(0.8, 0.8) rotate(270deg);
    }
    100% {
      left: 0px;
      top: 0px;
      transform: scale(0.6, 0.6) rotate(360deg);
    }
  }
  @keyframes rotate-wheel {
    0%{
      transform: rotate(0deg);
    }
    100%{
       transform: rotate(360deg);
    }
  }
}
