@import '../constants.scss';

$dotColor: $color-3;

@mixin scaled_die_size($x1, $dieSize) {
  .die {
    display: inline-block;
    margin: 5%;
    position: relative;
    height: $dieSize;
    width: $dieSize;
    perspective: $dieSize * 100;
    &-side {
      $x2: calc(2 * #{$x1});
      $x4: calc(4 * #{$x1});
      $x4n: calc(-4 * #{$x1});
      $x5: calc(5 * #{$x1});
      $x5n: calc(-5 * #{$x1});
      cursor: pointer;
      background-color: #eee;
      border: 2px solid #eee;
      border-radius: $x2;
      box-shadow: inset 10px -10px 40px -10px #ccc, inset -10px 10px 40px -10px #ccc, inset -5px 5px 20px 10px #fff, inset 5px -5px 20px 10px #fff, 0 0 5px $x1 #f4f4f4;
      box-sizing: border-box;
      display: block;
      margin: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      &:after {
        background: none transparent;
        border-radius: 100%;
        content: '';
        display: block;
        height: $dieSize/12;
        width: $dieSize/12;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:nth-child(1) {
        transform: rotateY(0deg) translateZ($dieSize/2);
        &:after {
          background-color: $dotColor;
          box-shadow: 0 0 0 $x1 $dotColor;
        }
      }
      &:nth-child(2) {
        transform: rotateX(180deg) translateZ($dieSize/2);
        &:after {
          box-shadow: $x5n $x4n 0 $x1 $dotColor, $x5 $x4n 0 $x1 $dotColor, 0 $x4n 0 $x1 $dotColor, $x5n $x4 0 $x1 $dotColor, $x5n $x4n 0 $x1 $dotColor, $x5 $x4 0 $x1 $dotColor, 0 $x4 0 $x1 $dotColor;
        }
      }
      &:nth-child(3) {
        transform: rotateY(90deg) translateZ($dieSize/2);
        &:after {
          box-shadow: $x4n $x4n 0 $x1 $dotColor, $x4 $x4 0 $x1 $dotColor, $x4 $x4n 0 $x1 $dotColor, $x4n $x4 0 $x1 $dotColor;
        }
      }
      &:nth-child(4) {
        transform: rotateY(-90deg) translateZ($dieSize/2);
        &:after {
          background-color: $dotColor;
          box-shadow: $x4n $x4n 0 $x1 $dotColor, 0 0 0 $x1 $dotColor, $x4 $x4 0 $x1 $dotColor;
        }
      }
      &:nth-child(5) {
        transform: rotateX(90deg) translateZ($dieSize/2);
        &:after {
          box-shadow: $x4n $x4n 0 $x1 $dotColor, $x4 $x4 0 $x1 $dotColor;
        }
      }
      &:nth-child(6) {
        transform: rotateX(-90deg) translateZ($dieSize/2);
        &:after {
          background-color: $dotColor;
          box-shadow: $x4 $x4n 0 $x1 $dotColor, $x4 $x4 0 $x1 $dotColor, $x4n $x4n 0 $x1 $dotColor, $x4n $x4 0 $x1 $dotColor, 0 0 0 $x1 $dotColor;
        }
      }
    }
    &.same {
      @keyframes shake {
        0% {
          transform: translate(30px, 30px);
        }
        33% {
          transform: rotate(-30px, 30px);
        }
        67% {
          transform: rotate(30px, -30px);
        }
        100% {
          transform: rotate(-30px, -30px);
        }
      }
      animation: shake 0.7s;
    }
    [data-roll='1'], [data-roll='2'], [data-roll='3'], [data-roll='4'], [data-roll='5'], [data-roll='6'] {
      animation-name: outro;
      animation-duration: 3s;
      animation-iteration-count: 1;
    }
    &.locked {
      [data-roll='1'], [data-roll='2'], [data-roll='3'], [data-roll='4'], [data-roll='5'], [data-roll='6'] {
        animation-name: outro;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }
    }
    &-inner {
      height: 100%;
      width: 100%;
      position: absolute;
      transform: translateZ($dieSize/-2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      transform-style: preserve-3d;
      transition: transform 0.5s ease;
      &[data-roll='1'] {
        transform: translateZ($dieSize/-2) rotateX(45deg) rotateY(0deg) rotateZ(-45deg);
      }
      &[data-roll='2'] {
        transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(45deg) rotateZ(0deg);
      }
      &[data-roll='3'] {
        transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(-45deg) rotateZ(90deg);
      }
      &[data-roll='4'] {
        transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(-45deg) rotateZ(-90deg);
      }
      &[data-roll='5'] {
        transform: translateZ($dieSize/-2) rotateX(135deg) rotateY(-45deg) rotateZ(0deg);
      }
      &[data-roll='6'] {
        transform: translateZ($dieSize/-2) rotateX(45deg) rotateY(180deg) rotateZ(-45deg);
      }
    }
  }
  
  @keyframes outro {
    0% {
      transform: translateZ($dieSize/-2) rotateX(45deg) rotateY(0deg) rotateZ(-45deg);
    }
    16% {
      transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(45deg) rotateZ(0deg);
    }
    32% {
      transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(-45deg) rotateZ(90deg);
    }
    48% {
      transform: translateZ($dieSize/-2) rotateX(-45deg) rotateY(-45deg) rotateZ(-90deg);
    }
    64% {
      transform: translateZ($dieSize/-2) rotateX(135deg) rotateY(-45deg) rotateZ(0deg);
    }
    82% {
      transform: translateZ($dieSize/-2) rotateX(45deg) rotateY(180deg) rotateZ(-45deg);
    }
    96% {
      transform: translateZ($dieSize/-2) rotateX(45deg) rotateY(0deg) rotateZ(-45deg);
    }
  }
}

@media screen and (max-width: 1300px) {
    @include scaled_die_size($x1: 3px, $dieSize: 60px);
}
@media screen and (min-width: 1301px) {
    @include scaled_die_size($x1: 6px, $dieSize: 120px);
}
