.RingEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; overflow: hidden;
  height: 100%;
  padding-bottom: 20px;
  // animation: fade-in 5s;
  position: relative;
  overflow: visible;
  transition: transform 2s, top 2s;
  top: 0;
  transform: scale(1,1);
  @media screen and (max-width: 768px) {
    overflow: hidden;
  }
  .Fellowship {
    @media screen and (max-width: 768px) {
      transform: scale(0.75, 0.75);
    }
  }
  &:not(.hide-hero) {
    top: 0;
  }
  &.hide-hero {
    top: 300px;
    opacity: 0;
    @keyframes spin-background{
      from {
        opacity: 0;
        transform: scale(6,6) rotate(0deg);
      }
      to {
        opacity: 1;
        transform: scale(1,1) rotate(360deg)
      }
    }
    animation: 4s ease-out forwards 4s spin-background;
    @media screen and (max-width: 768px) {
      top: 0px;
      @keyframes spin-background-mobile {
        from {
          opacity: 0;
          transform: scale(6,6);
        }
        to {
          opacity: 1;
          transform: scale(1,1);
        }
      }
      animation: 5s ease-out forwards 4s spin-background-mobile;
    }
    .hero {
      opacity: 0;
    }
  }
  &.zoom-in {
    animation: 4s linear forwards zoom-in;
  }
  &.zoom-out {
    animation: 4s linear forwards zoom-out;
    overflow: visible !important;
    .path {
      @keyframes path-glow {
        from {}
        to {
            filter: brightness(1.3) drop-shadow(0 0 648px rgb(0, 238, 255)) drop-shadow(0 0 348px rgb(41, 214, 6)) drop-shadow(0 0 448px rgb(146, 29, 255));
        }
      }
      @media screen and (min-width: 769px) {
        animation: 8s linear forwards path-glow;
      }
    }
    .forest-canvas {
      @keyframes forest-glow {
        from {}
        to {
            filter: brightness(1.1) drop-shadow(0 0 2px rgba(194, 255, 50, 0.5)); //drop-shadow(0 0 28px rgb(0, 238, 255));//  drop-shadow(0 0 148px rgb(146, 29, 255));
        }
      }
      @media screen and (min-width: 769px) {
        animation: 8s linear forwards forest-glow;
      }
    }
  }
  &.zoom-out-safari {
    animation: 4s ease-in forwards zoom-out;
    overflow: visible !important;
  }
  .path {
    position: absolute;
    width: 150%;
    height: 150%;
    background-size: cover;
    background-position: center;
    top: -50%;
    left: -15%;
    opacity: 0.5;
    @media screen and (max-width: 768px) {
      top: -50%;
      left: -0%;
    }
  }
  .forest-canvas {
    position: absolute;
    top: -146%;
    @media screen and (max-width: 768px) {
      // transform: scale(1.5, 1.5);
      // top: -380%;
      top: -1340px;
    }
  }
  .logo {
    position: absolute;
    top: -100%;
    font-size: 16vh;
    filter: drop-shadow(0 0 100px rgba(255, 255, 255, 0.75));
    @media screen and (max-width: 768px) {
      top: -110%;
      font-size: 10vh;
    }
    &:hover {
      background:red;
      &::after {
        content: 'Download Yout Exclusive Bonus Content';
        background: black;
        color: white;
      }
    }
  }

  $zoom-in-top: 77%;
  $zoom-in-scale: scale(2.5, 2.5);
  @keyframes zoom-in {
    from {top: 0}
    to {
      transform: $zoom-in-scale;
      top: $zoom-in-top;
    }
  }

  @keyframes zoom-out {
    from {
      transform: $zoom-in-scale;
      top: $zoom-in-top;
    }
    to {
      transform: scale(0.55, 0.55);
      overflow: visible;
      top: 47%;
    }
  }
}

.fireworks {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.download-container {
  color: black;
  text-decoration: none;
  position: absolute;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  margin: 40px;
  top: 0;
  right: 0;
  img {
    height: 40px;
    margin-left: 12px;
  }
}
.message {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 9999;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  cursor: none;
  @keyframes message-fade-in-out {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: 20s ease-in-out forwards message-fade-in-out;

}