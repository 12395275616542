@import './constants.scss';

.editor {
  box-sizing:content-box;
  border: 2px solid $color-1;
  border-radius: 8px;
  background: $main-background;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-family: monaco, monospace;
  .panels {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
  }
  .panel {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrapper-code {
    color: $color-1;
    font-family: monaco, monospace;
    padding: 0 10px;
    position: relative;
  }
  .wrapper-editor {
    // height: 100%;
    flex-grow: 1;
    // padding: 10px;
  }
  .info-bar {
    color: white;
    display: flex;
    justify-content: end;
    padding: 8px 0;
  }
  .action-bar {
    display: flex;
    justify-content: space-between;
    .tab-bar {
      display: flex;
      flex-direction: row;
      padding: 10px 10px 0 10px;
      button {
        cursor: pointer;
        font-family: monaco, monospace;
        padding: 4px 20px;
        margin: 0 1px;
        border: 1px solid rgba(0, 0, 0, 0.285);
        $tab-radius: 6px;
        border-top-left-radius: $tab-radius;
        border-top-right-radius: $tab-radius;
        background: $section-hightlight;
        border: 1px solid $section-hightlight-outline;
        border-bottom: none;
        transition: 1s all;
        // color: black;
        color: white;
        &:disabled {
          $color: rgba(223, 211, 211, 0.678);
          color: $color;
          background: $main-background;
          border: 1px solid $color;
          border-bottom: none;
        }
      }
    }
  }
}
