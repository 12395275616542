.Scene {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation-name: scene-transition;
  animation-fill-mode: both;
  animation-duration: 2s;
  .background-target {
    // height: 100%;
    // width: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
    animation: 5s forwards infinite spin;
    width: 0;
    height: 0;
  }
}

.dialogue {
  font-family: 'DotGothic16';
  box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.25);
  background-color: rgba(255,255,255,1);
  color: black;
  padding: 30px;
  border-radius: 4px;
  animation: fade-in 4s;
  position: relative;
  &::after {

  filter: drop-shadow(0 4px 4px rgba(255,255,255,0.25));
    $size: 80px;
    content: '';
    height: $size;
    width: $size;
    position: absolute;
    top: 100%;
    left: 50%;
    border: calc(#{$size} / 2) solid transparent;
    border-right-color: white;
    border-top-color: white;
    box-sizing: border-box;
  }
}

@keyframes scene-transition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}