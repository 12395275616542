@import '../constants.scss';

.MrBucketQuest {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  //font-family: 'Berkshire Swash';
  font-family: 'DotGothic16';
  bottom: 0;
  @media screen and (max-width: 768px) {
    text-align: center;
    .prompt {
      :nth-child(1) {
        font-size: 24px !important;
      }
      :nth-child(2) {
        font-size: 20px !important;
        color: #333;
        text-shadow: 0px 0px 4px rgba(255,255,255,0.5);
      }
    }
  }
  .prompt {
    position: absolute;
    top: 40px;
    left: 40px;
    :nth-child(1) {
      font-size: 40px;
    }
    :nth-child(2) {
      font-size: 28px;
      color: #333;
      text-shadow: 0px 0px 4px rgba(255,255,255,0.5);
    }
  }
  .scrubber {
    position: fixed;
    left: -200px;
    background: $color-4;
    border-radius: 100px;
    box-shadow: 0 0 4px 4px rgba(88, 81, 71, 0.801) inset, 0 0 4px 4px rgba(255,255,255,0.5);
    cursor: grabbing;
    pointer-events: none;
  }
  .hidden-character {
    height: 80%;
    pointer-events: none;
    @media screen and (max-width: 768px) {
      height: 50%;
    }
  }
  .opacity-layer, .canvas-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 1;
  }
  .canvas-layer {
    transition: opacity 6s;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    .smoke-canvas {
      &#smoke-canvas-2 {
        opacity: 0.8;
        height: 80%;
        width: 50%;
        left: 25%;
        top: 10%;
        filter: blur(20px);
        -webkit-filter: blur(20px);
        border-radius: 500px;
      }
      &#smoke-canvas-1 {
        opacity: 0.5;
      }
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // border-radius: 40px;
      box-shadow: 0 0 80px 8px white inset;
      filter: blur(10px);
      -webkit-filter: blur(10px);
    }
  }
}
.Narrator {
  .character {
    pointer-events: none;
  }
}