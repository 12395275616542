.Illusion {
  height: 300px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  &.round {
    .square {
      border-radius: 30%;
    }
  }

  &.morph {
    .square {
      @keyframes change-border-radius {
        from {
          border-radius: 0%;
        }
        to {
          border-radius: 50%;
        }
      }
      animation: 25s forwards alternate infinite ease-in-out change-border-radius, rotate 10s infinite linear;
    }
  }

  .square{
    height: 94%;
    width: 94%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -47% 0 0 -47%;
  }

  .black{ 
    background: black;  
    animation: rotate 10s infinite linear; 
  }

  @keyframes rotate{
    0%{ transform: rotate(0deg); }
    100%{ transform: rotate(360deg); }  
  }
}