
  .Fellowship {
    display: flex;
    position: relative;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: flex-end;
    z-index: 20;
    &.walking {
      @keyframes walk-to-house {
        0% {
          top: 0;
          right: 0;
          bottom: 0;
        }
        30% {
          transform: scale(0.8, 0.8);
          top: -20%;
          right: -12%;
        }
        60% {
          transform: scale(0.6, 0.6);
          top: -45%;
          right: 4%;
        }
        100% {
          transform: scale(0.4, 0.4);
          top: calc(-72% + 250px);
          right: 0%;
        }
      }
      animation: walk-to-house 20s ease-out;
      animation-fill-mode: forwards;
    }
  }
  .companion, .hero {
    position: relative;
    z-index: 10;
    filter: drop-shadow(0px 5px 40px #acacac7e);
    img {
      height: 180px;
    }
  }
  .companion {
    right: -1000px;
    bottom: -900px;
    animation: walk-in 6s ease-in-out 8s forwards;
  }
  .dialogue-1 {
    position: absolute;
    bottom: calc(10% + 250px);
  }
  .ring {
    align-self:flex-end;
    position: relative;
    top: -90px;
    transition: transform 1s;
    z-index: 20;
    filter: drop-shadow(0px 5px 20px #ffd000);
    &:hover {
      transform: scale(1.5, 1.5);
    }
    &.selected {
      @keyframes move-ring {
        from {
          left: 0;
        }
        to {
          top: 6px;
          left: 120px;
          transform: scale(0.2, 0.2);
        }
      }
      animation: move-ring 2s ease-in-out forwards;
    }
    img {
      transition: all 1s;
      height: 1px;
      animation-name: ring-animation;
      animation-duration: 4s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      cursor: pointer;
    }
  }
  @keyframes walk-in {
    from {
    }
    to {
      right: 0;
      bottom: 0;
    }
  }

  @keyframes ring-animation {
    from {
      filter: blur(50px);
    }
    to {
      filter: blur(0px);
      height: 80px;
      top: 200px;
    }
  }