$color-aqua-1: #e1fff2;
$color-aqua-2: #9de0e0;
$color-aqua-3: #45ADA8;
$color-aqua-4: #547980;
$color-aqua-5: #594F4F;

$gradient-orange-green: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);  

$color-1: #E4D4C8;
$color-2: #D0B49F;
$color-3: #A47551;
$color-4: #523A28;
$color-5: #594F4F;