.merge-rings {
  z-index: 5;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.5s;
  @keyframes show-rings {
    from {
      opacity: 1;
      transform: rotate(180deg);
    }
    to {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
  animation: 0.7s linear both show-rings;
  animation-iteration-count: 3;
  position: relative;
  top: 20%;
  img {
    margin: 20px;
    position: relative;
  }
  $scale-size: 0;
  $merge-speed: 4s;
  $merge-delay: 0s;
  .ring-1 {
    @keyframes merge-1 {
      0% {
        left: 0;
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(180deg);
      }
      50% {
        margin: 0;
        left: 25%;
        opacity: 1;
        transform: scale(.5, .5) rotate(0deg);
        filter: brightness(1);
      }
      100% {
        filter: brightness(20) blur(4);
        opacity: 0;
        left: 25%;
        display: none;
      }
    }
    animation: $merge-speed ease-in $merge-delay forwards merge-1;
  }
  .ring-2 {
    @keyframes merge-2 {
      0% {
        right: 0;
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(180deg);
      }
      50% {
        margin: 0;
        right: 25%;
        opacity: 1;
        transform: scale(.5, .5) rotate(0deg);
        filter: brightness(1);
      }
      100% {
        filter: brightness(20) blur(4);
        opacity: 0;
        right: 25%;
        display: none;
      }
    }
    animation: $merge-speed ease-in $merge-delay forwards merge-2;
  }
}
