.HouseCollage {
  position: fixed;
  top: 0;
  align-self:flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: all 1s;
  filter: drop-shadow(0 0 6px rgba(255,255,255,0.75));
  img {
    position: fixed;
  }
  .house-open {
    position: relative;
    height: 280px;
    z-index: 10;
  }
  .collage {
    top: 200px;
    height: 40px;
  }
}
