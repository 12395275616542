.JeopardyQuest {
  $fontfamily: 'DotGothic16';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .answer {
    margin-top: 40px;
    margin-left: 25%;
    margin-right: 25%;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 0px 0px 30px 1px white;
    padding: 4%;
    background-color: navy;
    font-family: $fontfamily;
    font-size: 2.5rem;
    text-shadow: 3px 3px 2px black;
    text-align: center;
    line-height: 1.4;
    font-weight: 700;
  }
  .question {
    margin-top: 80px;
    &.correct {
      input {
        transition: background-color 2s ease-in-out;
        background-color: rgba(0,0,0,0);
      }
    }
    input {
      border-width: 0;
      background: rgba(255,255,255,0.25);
      color: white;
      padding: 20px;
      border-radius: 8px;
      font-size: 4rem;
      font-family: $fontfamily;
      text-align: center;
      outline: none;
      cursor: text;
    }
  }
  @media screen and (max-width: 768px) {
    .answer {
      margin-top: 100px;
      font-size: 4vw;
      margin-left: 10%;
      margin-right: 10%;
    }
    .question {
      input {
        font-size: 2rem;
      }
    }
  }
}