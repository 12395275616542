@import './constants.scss';

.error {
  color: red;

  section {
    line-height: 3rem;
    color: $color-attention;
    padding: 40px;
    background: $section-light;
    border-radius: $border-radius-1;
  }
}