@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English:ital@0;1&display=swap');
@import '../constants.scss';

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Narrator {
  transition: opacity 2s;
  position: relative;
  z-index: 30;
  font-size: 1.2rem;
  .character {
    @media screen and (max-height: 600px) {
      height: 80px !important;
      left: 0 !important;
      &.right {
        left: unset !important;
        right: 0 !important;
      }
    }
    @keyframes slide-from-left {
      from {
        left: -400px;
      }
      to {
        left: 10%;
      }
    }
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    filter: drop-shadow(20px -5px 20px #181B00);
    position: fixed;
    bottom: 0px;
    left: 10%;
    transition: left 1s, bottom 1s, right 1s;
    &.right {
      right: 0;
      left: unset;
      animation-name: none;
    }
    &.left {
      animation-name: none;
      left: 0;
      right: unset;
    }
  }
  .bubble {
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    animation-name: fade-in;
    animation-duration: 4s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    overflow-x: hidden;
    overflow-y: auto;
    transition: height 0.5s;
    $width: 60%;
    color: black;
    width: $width;
    left: calc((100% - #{$width}) / 2);
    border-radius: 10px;
    opacity: 0.8;
    background: url('../img/brown-canvas.jpeg');
    background-color: rgba(228, 212, 200, 0.4);
    background-position: center;
    background-blend-mode: saturation;
    box-shadow: 0 0 80px 20px rgba(228, 212, 200, 0.5);
    border-radius: 4px;
    position: fixed;
    bottom: 250px;
    max-height: calc(100% - 250px);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 6px;
    // font-family: 'IM Fell English';
    font-family: 'DotGothic16';
    @media screen and (max-width: 600px) {
      $width: 70%;
      width: $width;
      left: 10%;
      .next-button {
        align-self: flex-end;
      }
    }
    &.failure {
      .result .result-text {
        animation-name: flash-red;
      }
      .next-button {
        @keyframes death-spin {
          from {}
          to {
          border-left-color:maroon;
          border-top-color: maroon;
          border-bottom-color: indianred;
          border-right-color: indianred;
          background-color: rgba(0,0,0,0.75);
          color: white;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          transform: rotate(90deg);
          border-radius: 100%;
          width: 30px;
          height: 50px;
          }
        }
        .basic-button {
          animation-name: death-spin;
          animation-duration: 0.2s; 
          animation-timing-function: ease-in-out;
          animation-fill-mode: both;
          animation-play-state: paused;
          @mixin spiral_button() {
            animation-play-state: running;
            background-color: white;
            box-shadow: 0 0 12px 12px rgba(255, 255, 255, 0.05) inset;
            border-width: 9px;
            border-top-color: maroon;
            border-bottom-color: maroon;
            animation-name: death-spin;
            text-shadow: 0 0 18px orangered;
            margin-top: 0;
          }
          @media screen and (max-size: 769px) {
            @include spiral_button();
          }
          &:hover {
            @include spiral_button();
          }
        }
      }
    }
  }
}

@keyframes flash-red {
  0% {
  }
  50% {
    color: red;
    opacity: 1;
  }
  100% {
    color: black;
    opacity: 1;
  }
}
