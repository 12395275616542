@import '../constants.scss';

.NasQuest {
  height: 100%;
  width: 100%;
  position: relative;
  // font-family: 'Berkshire Swash';
  font-family: 'DotGothic16';

  .prompt {
    padding-top: 5%;
    text-align: center;
    div {
      margin-bottom: 40px;
      font-size: 32px;
      &:nth-child(2) {
        font-size: 28px;
        color: $color-3;
      }
    }
  }

  .caption {
    @media screen and (max-width: 768px) {
      font-size: 100px;
    }
    text-align: center;
    font-size: 120px;
    .winning {
      color: $color-3;
    }
  }
  .dice-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    .prompt {
      div:nth-child(1) {
        margin-bottom: 20px;
        font-size: 28px;
        padding: 0 20px;
      }
      div:nth-child(2) {
        font-size: 20px !important;
      }
    }
  }

}

