.HUD {
  .progress {
    $size: 10vw;
    position: fixed;
    width: $size;
    height: $size; 
    border-radius: 9999px;
    top: 4%;
    left: calc(50% - #{$size}/2);
  }
}
