.crawl {
  width: 100%;
  height: 100%;
  // font: 700 1em "News Cycle", sans-serif;
  font: 700 1em "DotGothic16", sans-serif;
  letter-spacing:.15em;
	color: #ff6;
	overflow: hidden;
  margin: 0;
  section {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1;
  } 
  
  .intro {
    $intro-width: 60vw;
  	width: $intro-width;
    margin: 0 0 0 (- $intro-width / 2);
	  font-size: 3vw;
	  font-weight: 400;
  	color: rgb(75, 213, 238);
    opacity: 0;
    top: 35%;
	  animation: intro 6.5s ease-out 1s;
  }
  
  .logo {
    opacity: 0;
    animation: logo 9s ease-out 8.2s; 
	  font-size: 6vh;
    top: 90vh;
    width: 100%;
  }
  
  .titles {
    // $titles-width: 14.65em;
    $titles-width: 90vw;
    width: $titles-width;
    margin: 0 0 0 (- $titles-width / 2);
    top: auto;
    bottom: 0;
  	height: 50em;
  	// font-size: 350%;
  	font-size: 4vw;
  	text-align: justify;
	  overflow: hidden;
    transform-origin: 50% 100%;
    transform: perspective(300px) rotateX(15deg);
    color: #ff6;
  
    > div {
	    position: absolute;
	    top: 100%;
	    animation: titles 50s linear 10s;
    
      > p {
	      margin: 1.35em 0 1.85em 0;
        line-height: 1.35em;
        backface-visibility: hidden;
      }
    }
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }  
	20% { 
    opacity: 1; 
  }
	90% { 
    opacity: 1; 
  }
	100% { 
    opacity: 0;
  }
}

@keyframes logo {
	0% { 
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
    
    transform: scale(2.75); 
    opacity: 1; 
  }
	50% { 
    opacity: 1; 
    
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
  }
	100% { 
    transform: scale(.1); 
    opacity: 0;
    
    $logo-width: 18em;
  	width: $logo-width;
    margin: (- $logo-width / 2) 0 0 (- $logo-width / 2);
  }
}

@keyframes titles {
	0% { 
    top: 100%; 
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
	100% { 
    top: 0%; 
    opacity: 0;
  }
}